import React from 'react';

import { Formik, Form } from "formik";
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import Button from '~/components/Button';
import Input from '~/components/Input/Text';
import DatePicker from '~/components/Input/DatePicker';
import Select from '~/components/Input/Select';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    minHeight: '415px',
  },
  contact: {
    backgroundColor: 'transparent',
  },
  scheduling: {
    backgroundColor: '#f8f7ee',
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    width: '100%',
    height: '420px'
  },
  container: {
    marginTop: '22px',
    marginBottom: '60px'
  },
  button: {
    float: 'right',
    marginTop: '17px'
  },
  inputItem: {
  }
}));

export default function ContactForm(props) {
  const classes = useStyles();
  
  return <Formik
      initialValues={props.initialValues}
      validationSchema={props.schema}
      onSubmit={props.onSubmit}
    >
      {({ isSubmitting, dirty }) => (
        <React.Fragment>
            <Form style={{display: 'contents'}}>
              <div className={clsx(classes.root, {[classes.contact]: props.type === 'contact', [classes.scheduling]: props.type === 'scheduling'})}>
                <Grid container direction="row">
                    <Grid container direction="row" alignItems="center" className={classes.container}>
                      {/* <Grid container style={{marginBottom: '30px'}}> 
                        <Grid item xs={12}>
                          <Title>{props.title}</Title>
                        </Grid>
                      </Grid> */}

                      <Grid container spacing={3}>

                        { props.fields.length && props.fields.map((field, key) => {
                          if (field.type === 'datePicker') {
                            return <Grid key={key} item xs={field.cols} className={classes.inputItem}>
                              <DatePicker 
                                id={field.id}
                                name={field.name}
                                label={field.label}
                                format="dd/MM/yyyy"
                                disabled={ isSubmitting }
                                fullWidth
                              /> 
                            </Grid>
                          } else if (field.items) {
                            return <Grid key={key} item xs={field.cols} className={classes.inputItem}>
                              <Select
                                {...field}
                                disabled={isSubmitting || field.name === '_id' || field.name === 'id' || field.disabled || (field.disabledForCreate && props.form !== 'edit') || (field.disabledForUpdate && props.form === 'edit')}
                              />
                            </Grid>
                          } 

                          return <Grid key={key} item xs={12} md={field.cols} className={classes.inputItem}>
                            <Input
                              variant="outlined" 
                              id={field.id}
                              name={field.name}
                              label={field.label}
                              disabled={ isSubmitting }
                              multiline={field.multiline}
                              inputComponent={field.inputComponent}
                              rows={4}
                              rowsMax={4}
                              fullWidth
                            /> 
                          </Grid>
                        }) }

                        <Grid item xs={4}>
                          <Button type="submit" disabled={isSubmitting || !dirty} style={{float: 'left'}} className={classes.button}>{isSubmitting ? 'Aguarde' : props.submitButtonLabel}</Button>
                        </Grid>
                      </Grid>
                      
                    </Grid>
                  </Grid>
              </div>
            </Form>
        </React.Fragment>
      )}
    </Formik>
}
