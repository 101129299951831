import React, { useState } from 'react';

import styled from 'styled-components';
import { NavHashLink as Link } from 'react-router-hash-link';
import { useHistory } from "react-router-dom";

import { makeStyles, withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Fab from '@material-ui/core/Fab';

import Icon from '~/components/Icon';
import LogoSvg from '~/assets/icons/logo.svg';
import { ReactComponent as LogoWhatsAppSvg } from '~/assets/icons/whatsapp.svg';
import Container from '../Container';
import { Hidden } from '@material-ui/core';

const Logo = styled.img`
  cursor: pointer;
  height: 41px;
`;

const WhatsApp = styled.img`
  cursor: pointer;
  height: 41px;
`;

const Text = withStyles((theme) => ({
  root: {
    fontSize: '16px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.38,
    letterSpacing: 'normal',
    color: '#202020',
  },
}))(Typography);

const Text2 = withStyles((theme) => ({
  root: {
    fontSize: '14px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.57,
    letterSpacing: 'normal',
    color: '#ffffff',
  },
}))(Typography);

const Text3 = withStyles((theme) => ({
  root: {
    fontSize: '14px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.57,
    letterSpacing: 'normal',
    color: '#b5bdef',
  },
}))(Typography);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: '#ffffff',
  },
  root2: {
    flexGrow: 1,
    backgroundColor: '#283790',
  },
  footer: {
    width: '100%',
    minHeight: '172px',
  },
  footer2: {
    width: '100%',
    minHeight: '80px',
  },
  link: {
    fontSize: '18px',
    lineHeight: 1.94,
    color: '#8c8c8c',
    fontWeight: 'normal',
    display: 'flex'
  },
  icon: {
    fontSize: '40px',
    marginRight: '22px'
  },
  fab: {
    backgroundColor: '#6DD469',
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    '&:hover': {
      backgroundColor: '#6DD469',
    }
  },
}));

export default function CenteredGrid() {
  const classes = useStyles();
  const history = useHistory();

  return (
    <React.Fragment>
      <Hidden smDown>
        <div className={classes.root}>
          <Container>
            <Grid container className={classes.footer} alignItems="center" direction="row">
              <Grid container justify="flex-start" item xs={12} md={2}>
                <Logo onClick={() => history.push("/")} src={LogoSvg} />
              </Grid>
              <Grid container item xs={12} md={6}>
                <Grid item>
                  <Icon className={classes.icon} type="location" />
                </Grid>
                <Grid item>
                  <Text>Rodovia BR 101 Sul KM 29,6, No 3020</Text>
                  <Text>Distr. Indl. de Santo Estevão, Cabo de Santo Agostinho – PE.</Text>
                  <Text>CEP: 54503-010</Text>
                </Grid>
              </Grid>
              <Grid container item xs={12} md={4}>
                <Grid item>
                  <Text>Rodovia BR 101 KM 81,3, s/n</Text>
                  <Text>Prazeres, Jaboatão dos Guararapes – PE.</Text>
                  <Text>CEP: 54335-000</Text>
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </div>
      </Hidden>
      <div className={classes.root2}>
        <Container>
          <Grid container className={classes.footer2} alignItems="center" direction="row">
            <Hidden mdUp>
              <Grid container justify="center" item xs={12} md={6}>
                <Text2 style={{marginTop: '27px'}}>@ 2020</Text2>
              </Grid>
              <Grid container justify="center" item xs={12} md={6}>
                <Text2>Todos os direitos reservados</Text2>
              </Grid>
              <Grid container justify="center" item xs={12} md={6}>
                <Text2 style={{marginBottom: '24px'}}>Energy Service</Text2>
              </Grid>
              <Grid container justify="center" item xs={12} md={6}>
                <Text3 style={{marginBottom: '21px'}}>Desenvolvido por <b>Querosene</b></Text3>
              </Grid>
            </Hidden>
            <Hidden smDown>
              <Grid container justify="flex-start" item xs={12} md={6}>
                <Text2>@ 2020 • Todos os direitos reservados • Energy Service</Text2>
              </Grid>
              <Grid container justify="flex-end" item xs={12} md={6}>
                <Text3>Desenvolvido por <b>Querosene</b></Text3>
              </Grid>
            </Hidden>
          </Grid>
        </Container>
      </div>
      <Fab className={classes.fab} href="https://api.whatsapp.com/send?phone=5581973440110">
        <LogoWhatsAppSvg />
      </Fab>
    </React.Fragment>
  );
}
