import React from 'react';

import clsx from 'clsx';
import { useHistory } from "react-router-dom";

import { makeStyles, withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { Hidden } from '@material-ui/core';

import Icon from "~/components/Icon";
import Button from '~/components/Button';
import Container from '~/components/Container';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexGrow: 1,
    minHeight: '251px',
    backgroundColor: '#f2f2f2',
    paddingTop: theme.spacing(13),
    paddingBottom: theme.spacing(13)
  },
}));

const Text = withStyles((theme) => ({
  root: {
    fontSize: '32px',
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.19,
    letterSpacing: 'normal',
    color: '#202020',
  },
}))(Typography);


export default function CenteredGrid(props) {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Hidden smDown>
      <div className={clsx(classes.root)}>
        <Container>
          <Grid container>
              <Grid container justify="flex-start" alignItems="center" item xs={12} md={8}>
                <Text><Icon type="chat" /> {props.children}</Text>
              </Grid>
              <Grid container justify="flex-end" alignItems="center" item xs={12} md={4}>
                <Button onClick={() => history.push('/contato')} endIcon={<Icon type="arrow" />}>Formulário de contato</Button>
              </Grid>
          </Grid>
        </Container>
      </div>
    </Hidden>
  );
}