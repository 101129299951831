import React from 'react';

import clsx from 'clsx';
import styled from 'styled-components';
import { useHistory } from "react-router-dom";

import { makeStyles, withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { Box, Hidden } from '@material-ui/core';

import Icon from "~/components/Icon";
import Button from '~/components/Button';
import Container from '~/components/Container';
import Image from '~/assets/img/about_home.jpg';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginBottom: theme.spacing(16),
    marginTop: '60px',
  },
  bold: {
    fontWeight: 'bold !important'
  }
}));

const Text = withStyles((theme) => ({
  root: {
    marginTop: '5px',
    marginBottom: '24px',
    fontSize: '20px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.2,
    letterSpacing: 'normal',
    color: '#707070',
  },
}))(Typography);

const Text1 = withStyles((theme) => ({
  root: {
    fontSize: '32px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.19,
    letterSpacing: 'normal',
    color: '#202020',
  },
}))(Typography);

const Text2 = withStyles((theme) => ({
  root: {
    fontSize: '16px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.38,
    letterSpacing: 'normal',
    color: '#707070',
    marginBottom: '15px'
  },
}))(Typography);

const DivImage = styled.div`
  width: 100%;
  max-width: 649px;
  height: 419px;
  display: flex;
  img {
    object-fit: cover;
  }
`;

const Root = styled.div`
  flex-grow: 1;
  margin-bottom: 128px;
  margin-top: 120px;
  @media (max-width: 600px) {
    margin-top: 60px;  
  }
`;

export default function CenteredGrid() {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Container>
      <Root>
        <Grid container id="sobre">
          <Grid item xs={12} md={4}>
            <Box>
              <Text>EMPRESA</Text>
              <Text1>SERVIÇOS NOS</Text1>
              <Text1 style={{marginBottom: '24px'}} classes={{root: classes.bold}}>MELHORES PADRÕES TÉCNICOS E DE QUALIDADE</Text1>
              <Text2>Somos uma empresa especializada na manutenção e reparo de motores elétricos, geradores e transformadores.</Text2>
              <Hidden smDown>
                <Button onClick={() => history.push("/institucional")} endIcon={<Icon type="arrow" />}>Saiba mais</Button>
              </Hidden>
            </Box>
          </Grid>
          <Grid item xs={12} md={8}>
            <Box ml={{ md: 7 }}>
              <DivImage>
                <img src={Image} alt={''} width="100%" height="100%" />
              </DivImage>
            </Box>
            <Hidden mdUp>
              <Button fullWidth style={{marginTop: '24px'}} onClick={() => history.push("/institucional")} endIcon={<Icon type="arrow" />}>Sobre a Energy &nbsp;&nbsp;&nbsp;</Button>
            </Hidden>
          </Grid>
        </Grid>
      </Root>
    </Container>
  );
}
