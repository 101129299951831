import React from 'react';

import clsx from 'clsx';
import styled from 'styled-components';
import { useHistory } from "react-router-dom";

import Carousel from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';

import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';

import { makeStyles, withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { Box, Hidden, Paper } from '@material-ui/core';
import Link from '@material-ui/core/Link';

import Container from '~/components/Container';
import Icon from '~/components/Icon';
import Dots from '~/components/Dots';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    minHeight: '378px',
    backgroundColor: '#ffffff',
    position: 'relative',
    marginTop: '-73px'
  },
  icon: {
    fontSize: '44px',
    marginTop: '48px'
  },
  hoverReadMore: {
    backgroundColor: '#2eb673'
  },
  elevation2: {
    boxShadow: '0px 5px 8px 0px #d6d6d6'
  }
}));

const A = withStyles((theme) => ({
  root: {
    fontSize: '14px',
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.57,
    letterSpacing: 'normal',
    color: '#292929',
  },
}))(Link);

const Title = withStyles((theme) => ({
  root: {
    marginTop: '24px',
    marginBottom: '27px',
    fontSize: '18px',
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.19,
    letterSpacing: 'normal',
    color: '#202020',
  },
}))(Typography);

const Text = withStyles((theme) => ({
  root: {
    marginBottom: '16px',
    fontSize: '15px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '22px',
    letterSpacing: 'normal',
    color: '#707070',
  },
}))(Typography);

const ReadMore = styled.div`
  margin-bottom: 61px;
  display: inline-block;
  div {
    width: 100%;
    height: 2px;
    background-color: #292929;
  }
  &:hover div {
    background-color: #2eb673;
  }
`;

const DivImage = styled.div`
  width: 100%;
  height: 355px;  
  display: flex;
  img {
    object-fit: cover;
  }
`;

const Underline = styled.div``;

export default function CenteredGrid() {
  const classes = useStyles();
  const history = useHistory();
  const [activeStep, setActiveStep] = React.useState(0);

  const clickHandler = async (step) => {
    await setActiveStep(step);
  }

  const items = [
    <Grid item xs={12} md={3} key={0}>
      <Box ml={4}>
        <Icon type="gear" className={classes.icon} />
        <Title>Máquinas Rotativas</Title>
        <Text>Dispondo de um vasto conhecimento em máquinas rotativas, uma equipe altamente qualificada e uma estrutura...</Text>
        <ReadMore>
          <A underline="none" href="#cacc" onClick={() => history.push('/servicos')}>Leia mais</A>
          <Underline />
        </ReadMore>
      </Box>
    </Grid>,
    <Grid item xs={12} md={3} key={1}>
      <Box ml={3}>
        <Icon type="gear" className={classes.icon} />
        <Title>Transformadores</Title>
        <Text>Nossa larga experiência em processos de recuperação e repotencialização de transformadores...</Text>
        <ReadMore>
          <A underline="none" href="#transformadores" onClick={() => history.push('/servicos')}>Leia mais</A>
          <Underline />
        </ReadMore>
      </Box>
    </Grid>,
    <Grid item xs={12} md={3} key={1}>
      <Box mr={3}>
        <Icon type="gear" className={classes.icon} />
        <Title>Serviços de Campo</Title>
        <Text>Não limitados à nossa estrutura física, realizamos nossos serviços diretamente nas instalações dos nossos...</Text>
        <ReadMore>
          <A underline="none" href="#sc" onClick={() => history.push('/servicos')}>Leia mais</A>
          <Underline />
        </ReadMore>
      </Box>
    </Grid>,
    <Grid item xs={12} md={3} key={2}>
      <Box mr={4}>
        <Icon type="gear" className={classes.icon} />
        <Title>Geradores Eólicos</Title>
        {/* <Title style={{marginTop: 0}}>Eólicos</Title> */}
        <Text>Visando acompanhar as tendências do mercado de energia, criamos a Energy Wind Service, direcionada a...</Text>
        <ReadMore>
          <A underline="none" href="#eo" onClick={() => history.push('/servicos/wind')}>Leia mais</A>
          <Underline />
        </ReadMore>
      </Box>
    </Grid>
  ];

  const items2 = [
    <Grid component={Paper} variant="outlined" square>
      <Box p={1} key={0}>
        <Icon type="gear" className={classes.icon} />
        <Title>Máquinas Rotativas</Title>
        <Text>Dispondo de um vasto conhecimento em máquinas rotativas, uma equipe altamente qualificada e uma estrutura...</Text>
        <ReadMore>
          <A underline="none" href="#cacc" onClick={() => history.push('/servicos')}>Leia mais</A>
          <Underline />
        </ReadMore>
      </Box>
    </Grid>
    ,
    <Grid component={Paper} variant="outlined" square>
      <Box p={1} key={1}>
        <Icon type="gear" className={classes.icon} />
        <Title>Transformadores</Title>
        <Text>Nossa larga experiência em processos de recuperação e repotencialização de transformadores...</Text>
        <ReadMore>
          <A underline="none" href="#transformadores" onClick={() => history.push('/servicos')}>Leia mais</A>
          <Underline />
        </ReadMore>
      </Box>
    </Grid>
    ,
    <Grid component={Paper} variant="outlined" square>
      <Box p={1} key={2}>
        <Icon type="gear" className={classes.icon} />
        <Title>Serviços de Campo</Title>
        <Text>Não limitados à nossa estrutura física, realizamos nossos serviços diretamente nas instalações dos nossos...</Text>
        <ReadMore>
          <A underline="none" href="#sc" onClick={() => history.push('/servicos')}>Leia mais</A>
          <Underline />
        </ReadMore>
      </Box>
    </Grid>
    ,
    <Grid component={Paper} variant="outlined" square>
      <Box p={1} key={3}>
        <Icon type="gear" className={classes.icon} />
        <Title style={{marginBottom: 0}}>Geradores</Title>
        <Title style={{marginTop: 0}}>Eólicos</Title>
        <Text>Visando acompanhar as tendências do mercado de energia, criamos a Energy Wind Service, direcionada a...</Text>
        <ReadMore>
          <A underline="none" href="#eo" onClick={() => history.push('/servicos/wind')}>Leia mais</A>
          <Underline />
        </ReadMore>
      </Box>
    </Grid>
  ];

  return (
    <React.Fragment>
      <Hidden smDown>
        <Container>
          <div className={clsx(classes.root)}>
            <Grid className={classes.elevation2} container component={Paper} square elevation={2}>
              {/* <Grid container item xs={1} /> */}
              <Grid container justify="center" item xs={12}>
                {items}
              </Grid>
              {/* <Grid container item xs={1} /> */}
            </Grid>
          </div>
        </Container>
      </Hidden>
      <Hidden mdUp>
        <Container>
          <Carousel
            style={{marginTop: '-23px', backgroundColor: 'white !important', position: 'relative'}}
            value={activeStep}
            slides={items2}
            onChange={clickHandler}
          />
          <Dots activeStep={activeStep} clickHandler={clickHandler} steps={4}  />
        </Container>
      </Hidden>
    </React.Fragment>
  );
}
