import React from 'react';

import styled from 'styled-components';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Hidden } from '@material-ui/core';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import Form from '~/components/Form';
import PhoneMask from '~/components/Mask/Phone';
import Loading from '~/components/Loading';
import { sendContact } from '~/store/modules/contact/actions';
import Container from '~/components/Container';
import ContactInfo from '~/components/Contact';
import { Title, Title2 } from '~/components/Contact/elements';
import Icon from '~/components/Icon';
import clsx from 'clsx';

 const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  icon: {
    fontSize: '30px'
  },
  indicator: {
    height: '4px'
  },
  tab: {
    textTransform: 'unset'
  },
  wrapper: {
    '& svg': {
      fill: '#283790',
      marginRight: '10px'
    },
    flexDirection: 'unset',
    color: '#1f1f1f',
    fontSize: '16px',
  },
  disabled: {
    '& span': {
      color: '#707070 !important',
      '& svg': {
        fill: '#707070 !important'
      },
    },
  }
}));

const DivMap = styled.div`
  // padding: 0 15px;
  width: 100%;
  height: 420px;
  display: flex;
  border-radius: 8px;
  img {
    border-radius: 8px;
    object-fit: cover;
  }
`;

  const MyTab = styled(Tab)`
    svg {
      color: #283790;
      margin-right: 10px;
    }
  `;

const schema = Yup.object().shape({
  name: Yup.string()
    .required('O nome é obrigatório'),
  email: Yup.string()
    .email('Insira um e-mail válido')
    .required('O e-mail é obrigatório'),
  phone: Yup.string()
    .required('O telefone é obrigatório'),
  subject: Yup.string()
    .required('A área de interesse é obrigatória'),
  message: Yup.string()
    .required('A mensagem é obrigatório'),
});

const fields = [
  {
    id: 'name',
    name: 'name',
    label: 'Nome',
    cols: 6
  },
  {
    id: 'phone',
    name: 'phone',
    label: 'Telefone',
    inputComponent: PhoneMask,
    cols: 6
  },
  {
    id: 'subject',
    name: 'subject',
    label: 'Área de interesse',
    items: [
      {
        name: 'Técnico',
        value: 'technician'
      },
      {
        name: 'Financeiro',
        value: 'financial'
      },
      {
        name: 'Comercial',
        value: 'comercial'
      },
      {
        name: 'Suprimentos',
        value: 'supplies'
      },
      // {
      //   name: 'TI',
      //   value: 'ti'
      // },
    ],
    cols: 6
  },
  {
    id: 'email',
    name: 'email',
    label: 'Email',
    cols: 6
  },
  {
    id: 'message',
    name: 'message',
    label: 'Como podemos ajudar?',
    cols: 12,
    multiline: true
  },
];

function Contact () {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [loading, setLoading] = React.useState(true);
  const [value, setValue] = React.useState(0);
  const address = [
    'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3320.3118030168494!2d-35.00515943467607!3d-8.24908060089031!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xf86de8e913b3df2!2sEnergy%20Service%20LTDA!5e0!3m2!1spt-BR!2sbr!4v1600732076100!5m2!1spt-BR!2sbr',
    'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1174.1600981007705!2d-34.93900971349457!3d-8.163599841686821!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zOMKwMDknNDkuNyJTIDM0wrA1NicyMC4wIlc!5e0!3m2!1spt-BR!2sbr!4v1613695180217!5m2!1spt-BR!2sbr'
  ];

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const onSubmit = (data) => {
    console.log('onSubmit', data);

    dispatch(sendContact(data));
  }

  return (
    <div className={classes.root}>
      <Loading show={loading} />
      <Grid container>
        <Grid item xs={12}>
          <DivMap>
            {
              value === 0 ?
                <iframe onLoad={() => setLoading(false)} title="googleMaps" style={{borderRadius: '8px'}} width="100%" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0" src={address[0]}></iframe>
              :
                <iframe onLoad={() => setLoading(false)} title="googleMaps" style={{borderRadius: '8px'}} width="100%" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0" src={address[1]}></iframe>
            }
          </DivMap>
        </Grid>
        <Grid item xs={12}>
          <Tabs
            value={value}
            onChange={handleChange}
            classes={{indicator: classes.indicator}}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
            centered
          >
            <Tab className={clsx({[classes.disabled]: value !== 0})} classes={{root: classes.tab, wrapper: classes.wrapper}} icon={<Icon className={classes.icon} type="location" />} label="Unidade Máquinas Rotativas" />
            <Tab className={clsx({[classes.disabled]: value !== 1})} classes={{root: classes.tab, wrapper: classes.wrapper}} icon={<Icon className={classes.icon} type="location" />} label="Unidade Transformadores" />
          </Tabs>
        </Grid>
        {/* <Grid item xs={6}>
          <Grid container justify="center">
            Endereço 1
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Grid container justify="center">
            Endereço 2
          </Grid>
        </Grid> */}

        <Container style={{marginTop: '53px'}}>

          <Hidden mdUp>
            <Grid item xs={12}> 
              <Title>Entre em</Title>
              <Title2>contato</Title2>
            </Grid>
          </Hidden> 

          <Hidden smDown>
            <ContactInfo />  
          </Hidden>          

          <Grid item xs={12} md={8}> 
            <Form 
              type="contact"
              // title="Contato"
              schema={schema}
              fields={fields}
              onSubmit={onSubmit}
              initialValues={{name: '', email: '', phone: '',  subject: '', message: ''}}
              submitButtonLabel="ENVIAR"
            />
          </Grid>

          <Hidden mdUp>
            <ContactInfo />  
          </Hidden>   
        </Container>
      </Grid>
    </div>
  );
}

export default React.memo(Contact);