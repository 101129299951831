import React from "react";

import { useField  } from "formik";
import Select from '@material-ui/core/Select';

import { withStyles } from '@material-ui/core/styles';
import { MenuItem } from "@material-ui/core";
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';

const MySelect = withStyles((theme) => ({
  root: {
    color: '#707070 !important',
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  select: {
    "&:focus": {
      backgroundColor: 'transparent',
    }
  }
}))(Select);

const SelectComponent = props => {
  const attr = {...props};
  const [field, meta, helpers] = useField(props);
  const { setValue } = helpers;

  return <React.Fragment>
    <FormControl variant="outlined" fullWidth>
      <InputLabel id="demo-simple-select-outlined-label">{attr.label}</InputLabel>
      <MySelect
        {...props}
        {...field} 
        labelId="demo-simple-select-outlined-label"
        id="demo-simple-select-outlined"
        label={attr.label}
        value={meta.value}
        onChange={(event) => setValue(event.target.value)}
      >
      {
        attr.items &&
        attr.items.map((item, index) => (
          <MenuItem key={index} value={item.value}>
            { item.name }
          </MenuItem>
        ))
      }
      </MySelect>
      {meta.touched && meta.error ? (
        <div style={{color: '#f44336'}} className="MuiFormHelperText-root Mui-error">{meta.error}</div>
      ) : null}
    </FormControl>
  </React.Fragment>;
} 

export default SelectComponent;