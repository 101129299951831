import React from "react";

import clsx from "clsx";

import { makeStyles, withStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';

import styled from "styled-components";
import { TextField } from "formik-material-ui";
import { Field } from "formik";

const Component = styled(Field)``;

const useStyles = makeStyles((theme) => ({
  root: {
    color: '#707070 !important',
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  // underline: {
  //   color: '#f8f7ee',
  //   transform: 'scaleX(1)',
  //   '&:after': {
  //     borderBottom: '2px solid #202020',
  //   },
  //   '&:before': {
  //     borderBottom: '2px solid #202020',
  //   },
  //   '&:hover:not(.Mui-disabled):before': {
  //     borderBottom: '2px solid #202020',
  //   },
  // },
}));

const Label = withStyles({
  root: {
    color: '#8c8c8c',
    fontSize: '22px'
  },
})(InputLabel);

const Text = props => {
  const classes = useStyles();
  const attr = {...props};

  let label = null;
  // if (attr.label) {
  //     label = <Label htmlFor={props.id}>{attr.label}</Label>;
  //     attr.label = false;
  // }

  const inputStyle = { };
  const InputProps = {
    className: clsx(classes.root, classes.underline),
    inputComponent: attr.inputComponent,
    multiline: attr.multiline
  }

  return <React.Fragment>
    {label}
    <Component 
      {...attr} 
      component={TextField} 
      className={clsx({[classes.textarea]: attr.multiline, ...attr.className})} 
      InputProps={InputProps} 
      inputProps={{style: inputStyle}}
    >
      {attr.children}
    </Component>
  </React.Fragment>;
} 

export default Text;
