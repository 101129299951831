import React from 'react';

import Grid from '@material-ui/core/Grid';

export default function Container(props) {
  return (
    <Grid container>
      <Grid item xs={1} />          
      <Grid container direction="row" item xs={10} {...props}>
        {props.children}
      </Grid>          
      <Grid item xs={1} />          
    </Grid>
  );
}