import produce from 'immer';

const INITIAL_STATE = {
  projectsHome: [],
  projects: [],
  project: null,
};

export default function reducer(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case '@projects/setProjects': {
        draft.projects = action.payload.items;
        break;
      }
      case '@projects/setProjectsHome': {
        draft.projectsHome = action.payload.items;
        break;
      }
      case '@projects/setProject': {
        draft.project = action.payload.items;
        break;
      }
      default:
    }
  });
}
