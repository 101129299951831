import { takeLatest, call, put, all } from "redux-saga/effects";

import api from "~/shared/api";
import { setProjects, setProjectsHome, setProject } from '~/store/modules/projects/actions';

export function* fetchProjectsHome() {
  try {
    let url = `/projects/home.json`;

    const { data } = yield call(api.get, url);

    yield put(setProjectsHome(data.projects));

  } catch (err) {
  }
}

export function* fetchProjects() {
  try {
    let url = `/projects/all.json`;

    const { data } = yield call(api.get, url);

    yield put(setProjects(data.projects));

  } catch (err) {
  }
}

export function* getProject({payload}) {
  try {
    let url = `/projects/get/${payload.id}.json`;

    const { data } = yield call(api.get, url);

    yield put(setProject(data.project));

  } catch (err) {
  }
}

export default all([
  takeLatest("@projects/fetchProjectsHome", fetchProjectsHome),
  takeLatest("@projects/fetchProjects", fetchProjects),
  takeLatest("@projects/getProject", getProject),
]);
