import React from 'react';

import styled from 'styled-components';

import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';

import { makeStyles, withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Typography, Hidden } from '@material-ui/core';

import Container from '~/components/Container';
import Banner1 from '~/assets/img/banner1.png';
import Banner2 from '~/assets/img/banner2.png';
import Banner3 from '~/assets/img/banner3.png';
import Banner4 from '~/assets/img/banner4.jpg';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  center: {
    marginLeft: 'calc(50% - (24px/2))',
    marginTop: '-50px',
    cursor: 'pointer'
  },
  rotate: {
    transform: 'rotate(90deg)',
  }
}));

const DivImage = styled.div`
  width: 100%;
  height: 640px;  
  background-size:cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  img {
    object-fit: cover;
  }
  @media (max-width: 600px) {
    height: 240px;  
  }
`;

const TextBox = styled.div`
  position: absolute;
  z-index: 999;
  top: 45%; /* Adjust this value to move the positioned div up and down */
  text-align: left;
  max-width: 680px;
`;

const Title = withStyles((theme) => ({
  root: {
    fontSize: '56px',
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.18,
    color: '#ffffff',
  },
}))(Typography);

const SubTitle = withStyles((theme) => ({
  root: {
    fontSize: '18px',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.33,
    color: '#ffffff',
  },
}))(Typography);

export default function CenteredGrid(props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item xs={12}>
          <Splide options={{
            direction: 'ltr', 
            rewind : true,
            perPage: 1,
            perMove: 1,
            arrows : false,
            autoplay: true,
            pagination: true,
          }}>

            <SplideSlide key={0}>
              <DivImage style={{backgroundImage: `url(${Banner1})`}}>
                <Container>
                  <Hidden smDown>
                    <TextBox>
                      <Title variant="h3">Máquinas Rotativas</Title>
                      <SubTitle variant="h5">Dispondo de um vasto conhecimento em máquinas rotativas e uma equipe altamente qualificada, estamos aptos a realizar trabalhos de grande complexidade.</SubTitle>
                    </TextBox>
                  </Hidden>
                </Container>
                </DivImage>  
            </SplideSlide>

            <SplideSlide key={1}>
              <DivImage style={{backgroundImage: `url(${Banner4})`}}>
                <Container>
                  <Hidden smDown>
                    <TextBox>
                      <Title variant="h3">Transformadores</Title>
                      <SubTitle variant="h5">Possuímos larga experiência em processos de recuperação e repotencialização de transformadores.</SubTitle>
                    </TextBox>
                  </Hidden>
                </Container>
                </DivImage>  
            </SplideSlide>

            <SplideSlide key={2}>
              <DivImage style={{backgroundImage: `url(${Banner2})`}}>
                <Container>
                  <Hidden smDown>
                    <TextBox>
                      <Title variant="h3">Aerogeradores</Title>
                      <SubTitle variant="h5">Estamos preparados para realizar qualquer serviço de manutenção preventiva e corretiva em geradores para turbinas eólicas.</SubTitle>
                    </TextBox>
                  </Hidden>
                </Container>
                </DivImage>  
            </SplideSlide>

            <SplideSlide key={3}>
              <DivImage style={{backgroundImage: `url(${Banner3})`}}>
                <Container>
                  <Hidden smDown>
                    <TextBox>
                      <Title variant="h3">Campo</Title>
                      <SubTitle variant="h5">Não limitados à nossa estrutura física, realizamos nossos serviços diretamente nas instalações dos nossos clientes.</SubTitle>
                    </TextBox>
                  </Hidden>
                </Container>
                </DivImage>  
            </SplideSlide>

          </Splide>
        </Grid>
      </Grid>
    </div>
  );
}
