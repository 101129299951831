import React from 'react';

import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: 'black',
    opacity: '.5',
    width: '100%',
    height: '100%',
    left: '0px',
    top: '0px',
    position: 'absolute',
  },
  spinner: {
    position: 'absolute',
    left: '50%',
    top: '50%',
  },
  hidden: {
    display: 'none'
  }
}));

export default function CircularIndeterminate(props) {
  const classes = useStyles();

  return (
    <React.Fragment>
        <CircularProgress className={clsx(classes.spinner, {[classes.hidden]: !props.show})} color="primary" />
      {/* <div className={classes.root}>
      </div> */}
    </React.Fragment>
  );
}
