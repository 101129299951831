import React from "react";
import PropTypes from "prop-types";

import { Helmet } from 'react-helmet'
import styled from 'styled-components';

import Menu from "../Menu";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Container from '@material-ui/core/Container';
import Footer from '~/components/Footer';
import { Grid } from "@material-ui/core";
import Hidden from '@material-ui/core/Hidden';

import Icon from '~/components/Icon';
import Typography from '@material-ui/core/Typography';
import MyContainer from '~/components/Container';

export const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#ffffff'
  },
  container: {
    height: '100%',
    // marginTop: '120px'
  },
  link: {
    fontSize: '14px',
    lineHeight: 1.71,
    color: '#ffffff',
    fontWeight: 'normal',
    display: 'flex',
  }
}));

const FollowText = withStyles({
  root: {
    color: '#ffffff',
    fontSize: '14px',
    fontWeight: 'bold',
    lineHeight: 1.71,
  },
})(Typography);

const Bar = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  background-color: #283790;
  align-items: center;
  justify-content: flex-end;
`;

export default function DefaultLayout({ children, title }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Helmet><title>{ `Energy | ${title}`}</title></Helmet>
      <Bar>
        {/* <Container disableGutters>
          <MyContainer>
            <Hidden only="xs">
              <Grid container disableGutters justify="flex-end" item xs={12}>
                <FollowText style={{marginLeft: '36px'}}>Siga a Energy</FollowText>
                <a className={classes.link} style={{marginLeft: '36px'}} href="https://www.instagram.com/"><Icon style={{fontSize: '20px', marginRight: '9px'}} type="instagram" /> Instagram</a>
                <a className={classes.link} style={{marginLeft: '36px'}} href="https://www.facebook.com/"><Icon style={{fontSize: '20px', marginRight: '9px'}} type="facebook" /> facebook</a>
              </Grid> 
            </Hidden>
            <Hidden smUp>
              <Grid container alignItems="center">
                <Grid item xs={4}>
                  <FollowText>Siga a Energy</FollowText>
                </Grid>
                <Grid container justify="center" item xs={4}>
                  <a className={classes.link} href="https://www.instagram.com/"><Icon style={{fontSize: '20px', marginRight: '9px'}} type="instagram" /> Instagram</a>
                </Grid>
                <Grid container justify="flex-end" item xs={4}>
                  <a className={classes.link} href="https://www.facebook.com/"><Icon style={{fontSize: '20px', marginRight: '9px'}} type="facebook" /> Facebook</a>
                </Grid>
              </Grid>
            </Hidden>
          </MyContainer>
        </Container> */}
      </Bar>
      <Container maxWidth="lg" disableGutters className={classes.container}>
        <Menu />
        {children}
        <Footer />
      </Container>
    </div>
  );
}

DefaultLayout.propTypes = {
  children: PropTypes.element.isRequired,
};
