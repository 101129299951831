export function fetchProjectsHome() {
  return {
    type: '@projects/fetchProjectsHome',
  };
}

export function fetchProjects() {
  return {
    type: '@projects/fetchProjects',
  };
}

export function getProject(id) {
  return {
    type: '@projects/getProject',
    payload: {id}
  };
}

export function setProjects(items) {
  return {
    type: '@projects/setProjects',
    payload: {items}
  };
}

export function setProjectsHome(items) {
  return {
    type: '@projects/setProjectsHome',
    payload: {items}
  };
}

export function setProject(items) {
  return {
    type: '@projects/setProject',
    payload: {items}
  };
}