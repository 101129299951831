import React from 'react';

import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';

import DateFnsUtils from '@date-io/date-fns';
import { ptBR } from "date-fns/locale";

import { ToastContainer } from 'react-toastify';

import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

import './Config/ReactotronConfig';
import Routes from '~/routes';
import history from '~/shared/history';
import { store, persistor } from './store';
import GlobalStyle from '~/styles/global';

const values = {
  xs: 0,
  sm: 600, //600
  md: 960, //960
  lg: 1280, //1200
  xl: 1920,  //1920
};

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#283790',
    },
    secondary: {
      main: '#2bb673',
    },
  },
  typography: {
    fontFamily: "Rubik, sans-serif"
  },
  breakpoints: {
    values
  },
  overrides: {
    MuiInputBase: {
      input: {
        '&:-webkit-autofill': {
          transitionDelay: '9999s',
          transitionProperty: 'background-color, color',
        },
      },
    },
  },
});

function App() {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <Router history={history}>
          <ThemeProvider theme={theme}>
            <MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
              <Routes />
              <GlobalStyle />
              <CssBaseline />
              <ToastContainer autoClose={3000} />
            </MuiPickersUtilsProvider>
          </ThemeProvider>
        </Router>
      </PersistGate>
    </Provider>
  );
}

export default App;