import React, { useState, useEffect } from 'react';

import styled from 'styled-components';
import clsx from 'clsx';

import { makeStyles, withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import { Hidden } from '@material-ui/core';

import Help from '~/components/Help';
import Container from '~/components/Container';
import Service from '~/components/Services/Service';

import BannerCaCc from '~/assets/img/banner-ca-cc.jpg';
import BannerC from '~/assets/img/banner-campo.jpg';
import BannerEo from '~/assets/img/banner-geradores-eolicos.jpg';
import BannerT from '~/assets/img/banner-transformador.jpg';

import ImageAc1 from '~/assets/img/ac1.jpg';
import ImageAc2 from '~/assets/img/ac2.jpg';
import ImageAc3 from '~/assets/img/ac3.jpg';
import ImageAc4 from '~/assets/img/ac4.jpg';
import ImageAc5 from '~/assets/img/ac5.jpg';
import ImageAc6 from '~/assets/img/ac6.jpg';

import ImageAcB1 from '~/assets/img/ca-bobina1.jpg';
import ImageAcB2 from '~/assets/img/ca-bobina2.jpg';
import ImageAcB3 from '~/assets/img/ca-bobina3.jpg';
import ImageAcB4 from '~/assets/img/ca-bobina4.jpg';

import ImageTransf1 from '~/assets/img/transf1.jpg';
import ImageTransf2 from '~/assets/img/transf2.jpg';
import ImageTransf3 from '~/assets/img/transf3.jpg';
import ImageTransf4 from '~/assets/img/transf4.jpg';
import ImageTransf5 from '~/assets/img/transf5.jpg';
import ImageTransf6 from '~/assets/img/transf6.jpg';

import ImageCc1 from '~/assets/img/cc1.jpg';
import ImageCc2 from '~/assets/img/cc2.jpg';
import ImageCc3 from '~/assets/img/cc3.jpg';
import ImageCc4 from '~/assets/img/cc4.jpg';
import ImageCc5 from '~/assets/img/cc5.jpg';
import ImageCc6 from '~/assets/img/cc6.jpg';

import ImageCcB1 from '~/assets/img/cc-bobina1.jpg';
import ImageCcB2 from '~/assets/img/cc-bobina2.jpg';
import ImageCcB3 from '~/assets/img/cc-bobina3.jpg';
import ImageCcB4 from '~/assets/img/cc-bobina4.jpg';

import ImageC1 from '~/assets/img/campo1.jpg';
import ImageC2 from '~/assets/img/campo2.jpg';
import ImageC3 from '~/assets/img/campo3.jpg';
import ImageC4 from '~/assets/img/campo4.jpg';
import ImageC5 from '~/assets/img/campo5.jpg';
import ImageC6 from '~/assets/img/campo6.jpg';

import ImageEo1 from '~/assets/img/aero1.jpg';
import ImageEo2 from '~/assets/img/aero2.jpg';
import ImageEo3 from '~/assets/img/aero3.jpg';
import ImageEo4 from '~/assets/img/aero4.jpg';
import ImageEo5 from '~/assets/img/aero5.jpg';
import ImageEo6 from '~/assets/img/aero6.jpg';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  primaryText: {
    fontSize: '20px',
    color: '#3e3e3e',
    lineHeight: 1.2
  },
  activePrimaryText: {
    color: "#2eb673"
  },
  mobilePrimaryText: {
    fontSize: '24px',
    fontWeight: 'bold',
    color: '#2eb673',
    lineHeight: 1.17,
  },
  icon: {
    color: '#2eb673', 
    fontSize: '40px'
  },
  hideDivider: {
    '&:before': {
      opacity: 0
    }
  }
}));

const Title = withStyles((theme) => ({
  root: {
    fontSize: '20px',
    fontWeight: 'normal',
    lineHeight: 1.2,
    fontStretch: 'normal',
    fontStyle: 'normal',
    letterSpacing: 'normal',
    color: '#707070',
    marginTop: '53px'
  },
}))(Typography);

const Title2 = withStyles((theme) => ({
  root: {
    fontSize: '40px',
    fontWeight: 'bold',
    lineHeight: 1.18,
    fontStretch: 'normal',
    fontStyle: 'normal',
    letterSpacing: 'normal',
    color: '#262626',
    marginBottom: '47px'
  },
}))(Typography);

const MyListItem = withStyles((theme) => ({
  root: {
    height: '92px'
  },
}))(ListItem);

const Banner = styled.div`
  width: 100%;
  height: 400px;  
  display: flex;
  img {
    width: 100%;
    object-fit: cover;
  }
`;

const banners = {
  'cacc': BannerCaCc,
  'sc': BannerC,
  'eo': BannerEo,
  'transformadores': BannerT
};

const energyData = [
  {
    id: 'cacc',
    value: 'cacc',
    title: 'Máquinas rotativas ca + cc',
    text: 'Dispondo de um vasto conhecimento em máquinas rotativas, uma equipe altamente qualificada e uma estrutura física otimizada para atender a demanda de nossos clientes, a Energy Service oferece os seguintes serviços no que tange máquinas rotativas ca e cc:',
    subtitle: 'Máquinas ca:',
    items: [
      "Reprojetos, rebobinamentos e rejuvenescimentos de máquinas de corrente alternada;",
      "Correções em enrolamentos de máquinas de média tensão provocadas por efeito corona;",
      "Reprojetos, fornecimentos e adequações de sistemas de porta-escovas em geral;",
      "Reprojetos, confecções e usinagens de anéis coletores;",
      "Substituição parcial ou total de cunhas magnéticas ou não; ",
      "Substituição total ou parcial de pacotes magnéticos de estatores e de rotores;",
      "Embaralhamento e prensagem de pacotes magnéticos de estatores e de rotores;",
      "Recuperação e substituição de gaiolas rotóricas de barras;",
      "Usinagem e substituição de eixos de rotores;",
      "Recuperações mecânicas de assentos de rolamentos em eixos ou tampas;",
      "Recuperação e adaptação de mancais de metal patente;",
      "Verificação de correção de entreferros e centro magnético de motores e geradores;",
      "Balanceamento dinâmico de rotores até 8 toneladas;",
      "Impregnação a vácuo de motores de baixa tensão;",
      "Impregnação a vácuo pressão em motores de média tensão (VPI);",
      "Confecção de bobinas de média tensão até classe 15 kV;",
      "Ensaios elétricos para diagnósticos iniciais, para controle de processo durante os reparos e finais para liberação dos equipamentos. Dentre eles podemos destacar:",
      [	
        "Resistência de isolamento com megômetro digital com tensão até 10 kV e medição automática do IA e IP;",
        "Resistência ôhmica dos enrolamentos;",
        "Surge-test;",
        "Ensaio com indutor para verificação de trincas em barras de gaiolas ro-tóricas;",
        "Ensaio de queda-de-tensão em rotores de pólos;",
        "Ensaio de rotor aberto em máquinas com rotores com anéis coletores;",
        "Ensaio de tensão aplicada ac até 50 kV;",
        "Ensaio em vazio na tensão nominal da máquina elétrica com medição de: corrente, tensão, frequência e potência ativa e reativa além de verificações mecânicas, como vibração, aquecimento de rolamentos ou macais de metal patente, elevação de temperatura da carcaça e das bobinas, dentre outros."
      ]
    ],
    images: [
      ImageAc1,
      ImageAc2,
      ImageAc3,
      ImageAc4,
      ImageAc5,
      ImageAc6
    ],
    subImagesTitle: 'Fabricação de bobinas de motores CA',
    subImages: [
      ImageAcB1,
      ImageAcB2,
      ImageAcB3,
      ImageAcB4,
    ],
    subtitle2: 'Máquinas cc:',
    items2: [
      "Rebobinamentos e rejuvenescimentos de máquinas de corrente contínua;",
      "Reprojetos, fornecimentos e adequações de sistemas de porta-escovas em geral;",
      "Usinagem, quebra de cantos e rebaixamento de mica em comutadores;",
      "Substituição de comutadores ou reisolamento dos mesmos;",
      "Substituição total ou parcial de sapatas polares ou de armaduras;",
      "Embaralhamento e prensagem de sapatas polares ou de armaduras;",
      "Usinagem e substituição de eixos;",
      "Recuperações mecânicas de assentos de rolamentos em eixos ou tampas;",
      "Recuperação e adaptação de mancais de metal patente;",
      "Verificação e correção de entreferros e centro magnético de motores e geradores cc;",
      "Balanceamento dinâmico de armaduras até 8 toneladas;",
      "Impregnação a vácuo ou vácuo pressão de máquinas cc;",
      "Fabricação de bobinas de campo, interpólos, compensação, e de armadura;",
      "Ensaios elétricos para diagnósticos iniciais, para controle de processo durante os reparos e finais para liberação dos equipamentos. Dentre eles podemos destacar:",
      [
        "Resistência de isolamento com megômetro digital;",
        "Resistência ôhmica dos enrolamentos;",
        "Ensaio de queda de tensão nas bobinas de campo e interpólos;",
        "Ensaio de tensão aplicada ac;",
        "Ensaio em vazio com medição de corrente, tensão, além de verificações mecânicas, como vibração, aquecimento de lamentos ou mancais de metal patente, elevação de temperatura da carcaça e das bobinas, dentre outros;",
      ]
    ],
    images2: [
      ImageCc1,
      ImageCc2,
      ImageCc3,
      ImageCc4,
      ImageCc5,
      ImageCc6,
    ],
    subImages2Title: 'Fabricação de bobinas de motores CC',
    subImages2: [
      ImageCcB1,
      ImageCcB2,
      ImageCcB3,
      ImageCcB4,
    ],
  },
  {
    id: 'transformadores',
    value: 'transformadores',
    title: 'Transformadores',
    text: 'Nossa larga experiência em processos de recuperação e repotencialização de transformadores garante a capacitação técnica para análises precisas e a qualidade necessária para execução de um serviço de excelência. Dentro do escopo da reforma de transformadores, executamos os seguintes procedimentos:',
    items: [
      "Reprojeto, rebobinamento e rejuvenescimento de transformadores;",
      "Reprojeto e/ou repotenciação de transformadores;",
      "Repotenciação de transformadores em Nomex;",
      "Manutenção em TP’s e TC’s;",
      "Secagem da parte-ativa através de “hot oil spray”;",
      "Correção de vazamentos de óleo em oficina ou no campo;",
      "Tratamento termovácuo e/ou regeneração de óleo nas instalações do cliente ou em nossa oficina, com o transformador energizado ou não;",
      "Coleta e análise de óleo mineral isolante;",
      "Instalação de sistemas de ventilação forçada, painéis de comando, monitores eletrônicos de temperatura, etc;",
      "Execução de todos os ensaios de rotina, no campo e em oficina."
    ],
    images: [
      ImageTransf1,
      ImageTransf2,
      ImageTransf3,
      ImageTransf4,
      ImageTransf5,
      ImageTransf6,
    ],
    subImages: [],
    items2: [],
    images2: [],
    subImages2: [],
  },
  {
    id: 'sc',
    value: 'sc',
    title: 'Serviços de campo',
    text: 'Não limitados à nossa estrutura física, realizamos nossos serviços diretamente nas instalações dos nossos clientes. Nosso departamento de Serviço de Campo está devidamente estruturado com a equipagem necessária e uma equipe preparada para executar os seguintes serviços:',
    items: [
      "Projeto, montagem e manutenção de subestação até 320 kV;",
      "Manutenção em motores, geradores e transformadores;",
      "Manutenção geral em subestações, incluindo proteções, comandos, equipamentos, estrutura física, iluminação e proteção contra descargas atmosféricas;",
      "Balanceamento dinâmico de rotores;",
      "Análises de vibração em máquinas rotativas;",
      "Alinhamento entre eixos;",
      "Regeneração e tratamento com termovácuo de óleo mineral isolante;",
      "Secagem de transformadores através de processo “hot oil spray”;",
      "Coleta de amostras de óleo mineral isolante;",
      "Comissionamentos em Geral;",
      "Instalações elétricas industriais;",
      "Projeto e montagem de circuitos de iluminação industrial;",
      "Projeto e montagem para correção de fator de potência;",
      "Calibração e aferição de relés;",
      "Ensaios elétricos de resistência de isolamento, resistência ôhmica de enrolamentos e contatos, resistividade do solo, relação de tensões em transformadores, tensão, corrente, rotação, vibração, temperaturas, fator de potência, nível de harmônicas, etc."      
    ],
    images: [
      ImageC1,
      ImageC2,
      ImageC3,
      ImageC4,
      ImageC5,
      ImageC6,
    ],
    subImages: [],
    items2: [],
    images2: [],
    subImages2: [],
  },
];

const windData = [
  {
    id: 'eo',
    value: 'eo',
    title: 'Geradores para turbinas eólicas',
    text: 'Visando acompanhar as tendências do mercado de energia, criamos a Energy Wind Service, direcionada a entregar um serviço de alta qualidade em manutenção preventiva e corretiva de geradores. Com nossos esforços direcionados às turbinas eólicas, efetuamos os seguintes trabalhos:',
    items: [
      "Reprojetos, rebobinamentos e rejuvenescimentos;",
      "Reprojetos, fornecimentos e adequações de sistemas de porta-escovas em geral;",
      "Reprojetos, confecções e usinagens de anéis coletores;",
      "Substituição parcial ou total de cunhas magnéticas ou não; ",
      "Substituição total ou parcial de pacotes magnéticos de estatores e de rotores;",
      "Embaralhamento e prensagem de pacotes magnéticos de estatores e de rotores;",
      "Recuperação e substituição de gaiolas rotóricas de barras;",
      "Usinagem e substituição de eixos de rotores;",
      "Recuperações mecânicas de assentos de rolamentos em eixos ou tampas;",
      "Verificação de correção de entreferro e centro magnético;",
      "Balanceamento dinâmico de rotores até 8 toneladas;",
      "Impregnação a vácuo;",
      "Impregnação a vácuo pressão (VPI);",
      "Confecção de bobinas pré-moldadas para estatores;",
      "Confecção de barras de rotores;",
      "Reprojeto e modernização do ponto de fechamento dos rotores dos geradores (Star Point);",
      "Ensaios elétricos para diagnósticos iniciais, para controle de processo durante os reparos e finais para liberação dos equipamentos. Dentre eles podemos destacar:",
      [
        "Resistência de isolamento com megômetro digital com tensão até 10 kV e medição automática do IA e IP;",
        "Resistência ôhmica dos enrolamentos;",
        "Surge-test;",
        "Ensaio com indutor para verificação de trincas em barras de gaiolas ro-tóricas;",
        "Ensaio de rotor aberto em máquinas com rotores com anéis coletores;",
        "Ensaio de tensão aplicada ac até 50 kV;",
        "Ensaio em vazio na tensão nominal da máquina elétrica com medição de: corrente, tensão, frequência e potência ativa e reativa além de verificações mecânicas, como vibração, aquecimento de rolamentos, elevação de temperatura da carcaça e das bobinas, dentre outros.",
      ]
    ],
    images: [
      ImageEo1,
      ImageEo2,
      ImageEo3,
      ImageEo4,
      ImageEo5,
      ImageEo6,
    ],
    subImages: [],
    items2: [],
    images2: [],
    subImages2: [],
  },
];

function Services () {
  const classes = useStyles();
  const parts = window.location.href.split('#');
  const [active, setActive] = useState('ac');
  const [expanded, setExpanded] = useState(false);
  const [BannerImage, setBannerImage] = useState(BannerCaCc);
  const url =  window.location.protocol + "//" + window.location.host + window.location.pathname;
  
  useEffect(() => {
    if (window.location.href.indexOf('#') !== -1) {
      setActive(parts[1]);
      setBannerImage(banners[parts[1]]);
    } else {
      setActive('cacc');
      setBannerImage(banners['cacc']);
    }
  });
  
  console.log(BannerImage);

  const handlePopoverClick = (target) => {
    window.history.pushState({ path: `${url}#${target}`}, '', `${url}#${target}`);
    setBannerImage(banners[target]);
    setActive(target)
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  let content = energyData; 
  if (window.location.href.indexOf('wind') !== -1) {
    content = windData;
  }

  return <div className={classes.root}>
    <Grid container>
      <Banner>
        <img src={BannerImage} alt="banner" />
      </Banner>
    </Grid>
    <Container>
      <Grid container style={{marginBottom: '116px'}}>
        <Grid item xs={12}>
          <Title>Nossos</Title>
          <Title2>Serviços</Title2>
        </Grid>
        <Hidden smDown>
          <Grid item md={3}>
            <List component="nav">
              <React.Fragment>
                {content.map((item, k) => <React.Fragment>
                  <MyListItem button onClick={() => handlePopoverClick(item.value)}>
                    <ListItemText className={clsx(classes.primaryText, {[classes.activePrimaryText]: active === item.value})} disableTypography primary={item.title} />
                  </MyListItem>
                  {
                    k < content.length -1 ? <Divider /> : null
                  }
                </React.Fragment>)}
              </React.Fragment>
            </List>
          </Grid>
          <Grid item xs={12} md={9}> 
            {
              energyData.concat(windData).map(data => <Service 
                id={data.id}
                value={data.value}
                title={data.title}
                text={data.text}
                subtitle={data.subtitle}
                items={data.items}
                images={data.images}
                subImages={data.subImages}
                subImagesTitle={data.subImagesTitle}
                subtitle2={data.subtitle2}
                items2={data.items2}
                images2={data.images2}
                subImages2={data.subImages2}
                subImages2Title={data.subImages2Title}
                active={active}
              />)
            }
          </Grid>
        </Hidden>

        <Hidden mdUp>
          {
            content.map((data, k) => <Accordion className={{[classes.hideDivider]: k === 0}} elevation={0} expanded={expanded === k} onChange={handleChange(k)}>
              <AccordionSummary
                expandIcon={<ArrowDropDownIcon className={classes.icon} />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <Typography className={clsx(classes.mobilePrimaryText, classes.activePrimaryText)}>{data.title}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Service 
                  id={data.id}
                  value={data.value}
                  title={data.title}
                  text={data.text}
                  subtitle={data.subtitle}
                  items={data.items}
                  images={data.images}
                  subImages={data.subImages}
                  subImagesTitle={data.subImagesTitle}
                  subtitle2={data.subtitle2}
                  items2={data.items2}
                  images2={data.images2}
                  subImages2={data.subImages2}
                  subImages2Title={data.subImages2Title}
                  active={data.value}
                />
              </AccordionDetails>
            </Accordion>)
          }
        </Hidden>
      </Grid>
    </Container>
    <Help>Converse com a gente.</Help>
  </div>
}

export default Services;