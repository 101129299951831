import React from 'react';

import clsx from 'clsx';
import styled from 'styled-components';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  active: {
    width: '10px !important',
    height: '10px !important',
    backgroundColor: '#2eb673 !important',
  }
}));

const Dots = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
  width: 70px;
  margin-left: calc(50% - (90px / 2));
`;

const Dot = styled.span`
  height: 8px;
  width: 8px;
  background-color: #505050;
  border-radius: 50%;
  display: inline-block;
  margin-right: 10px;
  cursor: pointer;
`;

export default function CenteredGrid(props) {
  const classes = useStyles();
  let dots = [];
  for (let i = 0; i < props.steps; i++) {
    dots.push(<Dot key={i} onClick={() => props.clickHandler(i)} className={clsx({[classes.active]: i === props.activeStep})} />)
  }

  return (
    <Dots>
      {dots}
    </Dots>
  );
}
