import React from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';

import { ReactComponent as LogoIcon } from '~/assets/icons/logo.svg';
import { ReactComponent as ArrowIcon } from '~/assets/icons/arrow.svg';
import { ReactComponent as CloseIcon } from '~/assets/icons/close.svg';
import { ReactComponent as FacebookIcon } from '~/assets/icons/facebook.svg';
import { ReactComponent as InstagramIcon } from '~/assets/icons/instagram.svg';
import { ReactComponent as GearIcon } from '~/assets/icons/gear.svg';
import { ReactComponent as ChatIcon } from '~/assets/icons/chat.svg';
import { ReactComponent as PhoneIcon } from '~/assets/icons/phone.svg';
import { ReactComponent as LocationIcon } from '~/assets/icons/location.svg';
import { ReactComponent as ContactLocationIcon } from '~/assets/icons/contact_location.svg';
import { ReactComponent as ContactHourIcon } from '~/assets/icons/contact_hour.svg';
import { ReactComponent as ContactPhoneIcon } from '~/assets/icons/contact_phone.svg';

const Icon = (props) => {

  let Component = null, viewBox = '';
  switch (props.type) {
    case 'logo':
      Component = LogoIcon;
      viewBox = '0 0 170.501 59.437';
      break;
    case 'arrow':
      Component = ArrowIcon;
      viewBox = '0 0 27.949 12.785';
      break;
    case 'close':
      Component = CloseIcon;
      viewBox = '0 0 14 14';
      break;
    case 'facebook':
      Component = FacebookIcon;
      viewBox = '0 0 16 16';
      break;
    case 'instagram':
      Component = InstagramIcon;
      viewBox = '0 0 16 16';
      break;
    case 'gear':
      Component = GearIcon;
      viewBox = '0 0 39.925 40';
      break;
    case 'chat':
      Component = ChatIcon;
      viewBox = '0 0 40 36';
      break;
    case 'location':
      Component = LocationIcon;
      viewBox = '0 0 29.412 40';
      break;
    case 'phone':
      Component = PhoneIcon;
      viewBox = '0 0 39.291 40';
      break;
    case 'contact_phone':
      Component = ContactPhoneIcon;
      viewBox = '0 0 39.289 40';
      break;
    case 'contact_hour':
      Component = ContactHourIcon;
      viewBox = '0 0 40 40';
      break;
    case 'contact_location':
      Component = ContactLocationIcon;
      viewBox = '0 0 29.408 39.998';
      break;
  
    default:
      break;
  }
  return (
    <SvgIcon component={Component} viewBox={viewBox} {...props} />
  );
}

export default Icon;