import React  from 'react';

import styled from 'styled-components';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app
import _ from 'lodash';

import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Box, Hidden } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

const Title = withStyles((theme) => ({
  root: {
    fontSize: '40px',
    fontWeight: 'bold',
    lineHeight: 1.18,
    fontStretch: 'normal',
    fontStyle: 'normal',
    letterSpacing: 'normal',
    color: '#2eb673',
    marginBottom: '32px'
  },
}))(Typography);

const Title2 = withStyles((theme) => ({
  root: {
    fontSize: '32px',
    fontWeight: 'bold',
    lineHeight: 1.18,
    fontStretch: 'normal',
    fontStyle: 'normal',
    letterSpacing: 'normal',
    color: '#2eb673',
    marginBottom: '32px'
  },
}))(Typography);

const Text = withStyles((theme) => ({
  root: {
    fontSize: '22px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.82,
    letterSpacing: 'normal',
    color: '#707070',
  },
}))(Typography);

const Image = styled.div`
  cursor: pointer;
  margin-bottom: 16px;
  width: 100%;
  max-width: 358px;
  height: 294px;
  display: flex;
  img {
    width: 100%;
    object-fit: cover;
  }
`;

const Ul = styled.ul`
  margin-bottom: 84px;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.78;
  letter-spacing: normal;
  text-align: left;
  color: #525252;
  li::before {
    content: "• ";
  }
`;

function Service (props) {
  const { images, subImages, images2, subImages2 } = props;
  const [open, setOpen] = React.useState(false);
  const [open11, setOpen11] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const [open21, setOpen21] = React.useState(false);
  const [photoIndex, setPhotoIndex] = React.useState(0);
  
  return props.active !== props.value ? null :
    <Grid container id={props.id} value={props.value}>
      <Box pl={{md:6}} pt={{md: 3}}>
        <Hidden smDown>
          <Title>{props.title}</Title>
        </Hidden>
        <Text style={{marginBottom: '50px'}}>{props.text}</Text>
        
        <Text style={{marginBottom: '10px'}}><b>{props.subtitle}</b></Text>
        <Ul>
          {props.items.map(item => {
            if (_.isArray(item)) {
              return item.map(subItem => <li style={{marginLeft: '40px'}}>{subItem}</li>)
            }
            return <li>{item}</li>;
          })}
        </Ul>

        <Grid container justify="space-between">
          {props.images.map((image, imageIndex) => <Grid item xs={12} md={6} onClick={() => {setPhotoIndex(imageIndex); setOpen(true);}}>
            <Image>
              <img src={image} alt="banner" />
            </Image>
          </Grid>)}
        </Grid>

        {open && (
          <Lightbox
            mainSrc={images[photoIndex]}
            nextSrc={images[(photoIndex + 1) % images.length]}
            prevSrc={images[(photoIndex + images.length - 1) % images.length]}
            onCloseRequest={() => setOpen(false)}
            onMovePrevRequest={() => setPhotoIndex((photoIndex + images.length - 1) % images.length)}
            onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % images.length)}
          />
        )}
        
        {
          props.subImagesTitle ?
            <Text style={{marginBottom: '10px'}}><b>{props.subImagesTitle}</b></Text>
          :
            null
        }
        <Grid container justify="space-between">
          {props.subImages.map((image, imageIndex) => <Grid item xs={12} md={6} onClick={() => {setPhotoIndex(imageIndex); setOpen11(true);}}>
            <Image>
              <img src={image} alt="banner" />
            </Image>
          </Grid>)}
        </Grid>

        {open11 && (
          <Lightbox
            mainSrc={subImages[photoIndex]}
            nextSrc={subImages[(photoIndex + 1) % subImages.length]}
            prevSrc={subImages[(photoIndex + subImages.length - 1) % subImages.length]}
            onCloseRequest={() => setOpen11(false)}
            onMovePrevRequest={() => setPhotoIndex((photoIndex + subImages.length - 1) % subImages.length)}
            onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % subImages.length)}
          />
        )}

        <Text style={{marginBottom: '10px'}}><b>{props.subtitle2}</b></Text>
        <Ul>
          {props.items2.map(item => {
            if (_.isArray(item)) {
              return item.map(subItem => <li style={{marginLeft: '40px'}}>{subItem}</li>)
            }
            return <li>{item}</li>;
          })}
        </Ul>

        <Grid container justify="space-between">
          {props.images2.map((image, imageIndex) => <Grid item xs={12} md={6} onClick={() => {setPhotoIndex(imageIndex); setOpen2(true);}}>
            <Image>
              <img src={image} alt="banner" />
            </Image>
          </Grid>)}
        </Grid>

        {open2 && (
          <Lightbox
            mainSrc={images2[photoIndex]}
            nextSrc={images2[(photoIndex + 1) % images2.length]}
            prevSrc={images2[(photoIndex + images2.length - 1) % images2.length]}
            onCloseRequest={() => setOpen2(false)}
            onMovePrevRequest={() => setPhotoIndex((photoIndex + images2.length - 1) % images2.length)}
            onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % images2.length)}
          />
        )}
        
        {
          props.subImages2Title ?
            <Text style={{marginBottom: '10px'}}><b>{props.subImages2Title}</b></Text>
          :
            null
        }

        <Grid container justify="space-between">
          {props.subImages2.map((image, imageIndex) => <Grid item xs={12} md={6} onClick={() => {setPhotoIndex(imageIndex); setOpen21(true);}}>
            <Image>
              <img src={image} alt="banner" />
            </Image>
          </Grid>)}
        </Grid>

        {open21 && (
          <Lightbox
            mainSrc={subImages2[photoIndex]}
            nextSrc={subImages2[(photoIndex + 1) % subImages2.length]}
            prevSrc={subImages2[(photoIndex + subImages2.length - 1) % subImages2.length]}
            onCloseRequest={() => setOpen21(false)}
            onMovePrevRequest={() => setPhotoIndex((photoIndex + subImages2.length - 1) % subImages2.length)}
            onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % subImages2.length)}
          />
        )}
      </Box>
    </Grid>
}

export default Service;