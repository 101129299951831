import React from 'react';

import Highlights from '~/components/Home/Highlights';
import About from '~/components/Home/About';
import Help from '~/components/Help';
import Services from '~/components/Home/Services';

function Home () {
  return <React.Fragment>
    <Highlights />
    <Services />
    <About />
    <Help>Converse com a gente.</Help>
  </React.Fragment>;
}

export default Home;