import { createGlobalStyle } from 'styled-components';

import 'react-toastify/dist/ReactToastify.css';

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
  }

  *:focus {
    outline:0;
  }

  html, body, #root {
    height: 100%;
    background: #F3F5F8;
  }

  body {
    -webkit-font-smoothing: antialiased;
    background: #F3F5F8;
  }

  a {
    text-decoration: none;
  }

  ul {
    list-style: none;
  }

  button {
    cursor: pointer;
  }

  // Datepicker customization
  .rdrMonthAndYearWrapper {
    background-color: #F8F8F8;
    height: 90px !important;  
  }

  // toastfy
  .Toastify__toast--default {
    background: #2eb673;
    color: #000000;
  }

  // Splide
  .splide__pagination {
    display: inline-flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    z-index: 1;
    bottom: 13.54em;
    position: absolute;
    left: 0;
    width:auto;
    transform: inherit;
    margin-left: 8.333333%;
  }

  @media screen and (max-width: 960px) {
    .splide__pagination {
      display: none;
    }
  }

  .splide__pagination__page {
    width: 16px;
    border-radius: 0;
  }
  
  .splide__pagination__page.is-active {
    transform: none;
  }

  .BrainhubCarousel__container {
    margin-top: -23px;
    background-color: white !important;
    position: relative;
  }
`;
