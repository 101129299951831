import React, { useEffect } from 'react';

import styled from 'styled-components';
import clsx from 'clsx';
import { useHistory } from "react-router-dom";
import { NavHashLink as Link } from 'react-router-hash-link';

import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Hidden from '@material-ui/core/Hidden';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Popover from '@material-ui/core/Popover';

import LogoSvg from '~/assets/icons/logo.svg';
import LogoWindSvg from '~/assets/icons/logo_wind.svg';
import WegSvg from '~/assets/icons/weg.svg';
import IsoSvg from '~/assets/icons/iso9001.svg';
import WegWhiteSvg from '~/assets/icons/wegWhite.svg';
import Container from '~/components/Container';

const drawerWidth = 280;

const useStyles = makeStyles((theme) => ({
  root: {
    // display: 'flex',
  },
  title: {
    flexGrow: 1,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    backgroundColor: theme.palette.primary.main,
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start',
  },
  divider: {
    backgroundColor: '#3f51bf'
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  listItem: {
    height: '22px'
  },
  mobileListItem: {
    height: '76px'
  },
  mobileSubListItem: {
    height: '46px'
  },
  popover: {
    // pointerEvents: 'none',
  },
  paper: {
    padding: theme.spacing(1),
    minWidth: "247px",
    minHeight: "165px",
    border: 'solid 2px #2eb673',
    marginTop: '17px'
  },
  button: {
    backgroundColor: 'transparent !important'
  },
}));

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    height: '118px',
    width: '100%'
  }
}));

const useTypographStyles = makeStyles((theme) => ({
  root: {
    fontSize: '20px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.2,
    letterSpacing: 'normal',
    textAlign: 'left',
    color: '#333333',
    '&:hover': {
      color: '#2eb673',
    }
  },
  active: {
    color: '#2bb673'
  },
  pointer: {
    cursor: 'pointer',
  },
  subMenu: {
    fontSize: '18px',
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.22,
    letterSpacing: 'normal',
    textAlign: 'left',
    color: '#262626',
    '&:hover': {
      color: '#2eb673',
    }
  },
  subMenuItem: {
    fontSize: '16px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.5,
    letterSpacing: 'normal',
    textAlign: 'left',
    color: '#262626',
    '&:hover': {
      color: '#2eb673',
    }
  },
}));

const useMobileTypographStyles = makeStyles((theme) => ({
  root: {
    fontSize: '24px',
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.17,
    letterSpacing: 'normal',
    textAlign: 'left',
    color: '#ffffff',
    display: 'inline-block',
    '&:hover': {
      color: '#2eb673',
    }
  },
  active: {
    color: '#2bb673'
  },
  subItem: {
    fontSize: '18px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.22,
    letterSpacing: 'normal',
    textAlign: 'left',
    color: '#ffffff',
    display: 'inline-block',
    '&:hover': {
      color: '#2eb673',
    }
  }
}));

const useAppbarStyles = makeStyles((theme) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
  },
  colorPrimary: {
    backgroundColor: 'transparent',
    color: theme.palette.primary.main
  },
}));

const Logo = styled.img`
  cursor: pointer;
  height: 48px;
`;

const Menu = styled.div`
  width: 100%;
  height: 118px;
  display: flex;
  background-color: #ffffff;
`;

const PopoverLink = styled.a`
`;

export default function PersistentDrawerRight() {
  const classes = useStyles();
  const history = useHistory();
  const appBarClasses = useAppbarStyles();
  const toolBarClasses = useToolbarStyles();
  const typographClasses = useTypographStyles();
  const mobileTypographClasses = useMobileTypographStyles();
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [LogoIcon, setLogoIcon] = React.useState(null);

  const handlePopoverOpen = (event) => {
    console.log('handlePopoverOpen', event.currentTarget);
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  
  const openPopover = Boolean(anchorEl);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const scrollWithOffset = (el, offset) => {
    const elementPosition = el.offsetTop - offset;
    window.scroll({
      top: elementPosition,
      left: 0,
      behavior: "smooth"
    });    
  }

  useEffect(() => {
    setLogoIcon(window.location.href.indexOf('wind') !== -1 ? LogoWindSvg : LogoSvg)
  });

  return (
    <div className={classes.root}>
      <Container>
        <Menu
          elevation={0}
          position="sticky"
          className={clsx(appBarClasses.appBar, appBarClasses.colorPrimary, {
            [appBarClasses.appBarShift]: open,
          })}
        >
          <Toolbar className={clsx(toolBarClasses.root)} disableGutters>
            <Grid container item sm={3} lg={3}> 
              <Logo onClick={() => history.push("/")} src={LogoIcon} />
            </Grid>

            <Hidden smDown>
              <Grid container item sm={6} lg={6} direction="row" justify="flex-start" alignItems="center"> 
                <Grid>
                  <Box ml={5}>
                    <Link activeClassName={typographClasses.active} className={typographClasses.root} to="/" exact>
                      Home
                    </Link>
                  </Box>
                </Grid>
                <Grid>
                  <Box ml={5}>
                    <Link activeClassName={typographClasses.active} className={typographClasses.root} to="/institucional">
                      Institucional
                    </Link>
                  </Box>
                </Grid>
                <Grid>
                  <Box ml={5}>
                    <span 
                      aria-owns={openPopover ? 'mouse-over-popover' : undefined}
                      aria-haspopup="true"
                      onClick={handlePopoverOpen}
                      className={clsx(typographClasses.pointer, typographClasses.root, {[typographClasses.active]: window.location.href.indexOf('servicos') !== -1})} 
                      href="/#">
                      Serviços
                    </span>
                  </Box>
                </Grid>
                <Grid>
                  <Box ml={5}>
                  <Link activeClassName={typographClasses.active} className={typographClasses.root} to="/contato">
                    Contato
                  </Link>
                  </Box>
                </Grid>
              </Grid>
              <Grid container direction="row" justify="flex-end" alignItems="center" item sm={3} lg={3}> 
                <Logo onClick={() => history.push("/")} src={WegSvg} />
                <Logo onClick={() => history.push("/")} src={IsoSvg} style={{marginLeft: '16px'}} />
              </Grid>
            </Hidden>  

            <Hidden mdUp>
              <Grid container item sm={10} direction="row" justify="flex-end" alignItems="center"> 
                <IconButton
                  color="primary"
                  aria-label="open drawer"
                  edge="end"
                  onClick={handleDrawerOpen}
                  className={clsx(open && classes.hide)}
                >
                  <MenuIcon fontSize="large" />
                </IconButton>
              </Grid>
            </Hidden>
          </Toolbar>
        </Menu>
      </Container>
      
      <Popover
        id="mouse-over-popover"
        className={classes.popover}
        classes={{
          paper: classes.paper,
        }}
        open={openPopover}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <List component="nav">
          <ListItem button onClick={() => setAnchorEl(null)} className={classes.listItem} classes={{button: classes.button}}>
            <PopoverLink onClick={() => history.push("/servicos#cacc")} className={clsx(typographClasses.subMenu)}>
              <ListItemText disableTypography primary={'Energy Service'} />
            </PopoverLink>
          </ListItem>
          <ListItem button onClick={() => setAnchorEl(null)} className={classes.listItem} classes={{button: classes.button}} style={{marginTop: '10px'}}>
            <PopoverLink onClick={() => history.push("/servicos#cacc")} className={clsx(typographClasses.subMenuItem)}>
              <ListItemText disableTypography primary={'Máquinas rotativas ca + cc'} />
            </PopoverLink>
          </ListItem>
          <ListItem button onClick={() => setAnchorEl(null)} className={classes.listItem} classes={{button: classes.button}}>
            <PopoverLink onClick={() => history.push("/servicos#transformadores")} className={clsx(typographClasses.subMenuItem)}>
              <ListItemText disableTypography primary={'Transformadores'} />
            </PopoverLink>
          </ListItem>
          <ListItem button onClick={() => setAnchorEl(null)} className={classes.listItem} classes={{button: classes.button}}>
            <PopoverLink onClick={() => history.push("/servicos#sc")} className={clsx(typographClasses.subMenuItem)}>
              <ListItemText disableTypography primary={'Serviços de Campo'} />
            </PopoverLink>
          </ListItem>

          <ListItem button onClick={() => setAnchorEl(null)} className={classes.listItem} classes={{button: classes.button}} style={{marginTop: '31px'}}>
            <PopoverLink onClick={() => history.push("/servicos/wind#eo")} className={clsx(typographClasses.subMenu)}>
              <ListItemText disableTypography primary={'Energy Wind Service'} />
            </PopoverLink>
          </ListItem>

          <ListItem button onClick={() => setAnchorEl(null)} className={classes.listItem} classes={{button: classes.button}} style={{marginTop: '10px'}}>
            <PopoverLink onClick={() => history.push("/servicos/wind#eo")} className={clsx(typographClasses.subMenuItem)}>
              <ListItemText disableTypography primary={'Geradores para turbinas eólicas'} />
            </PopoverLink>
          </ListItem>
        </List>
      </Popover>

      <Drawer
        className={classes.drawer}
        anchor="right"
        open={open}
        onClose={handleDrawerClose}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader} onClick={handleDrawerClose}>
          {/* <IconButton >
            {theme.direction === 'rtl' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton> */}
        </div>
        <List>
          <ListItem button className={classes.mobileListItem} key={'Home'} onClick={handleDrawerClose}>
            <Link activeClassName={mobileTypographClasses.active} to="/" >
              <ListItemText disableTypography primary={'Home'} className={mobileTypographClasses.root} style={{color: 'white'}} />
            </Link>
          </ListItem>
          <Divider classes={{root: classes.divider}} />
          <ListItem button className={classes.mobileListItem} key={'Institucional'} onClick={handleDrawerClose}>
            <Link activeClassName={mobileTypographClasses.active} to="/institucional" className={mobileTypographClasses.root}>
              <ListItemText disableTypography primary={'Institucional'} style={{color: 'white'}} />
            </Link>
          </ListItem>
          <Divider classes={{root: classes.divider}} />
          <ListItem button className={classes.mobileListItem} key={'Serviços'} onClick={handleDrawerClose}>
            <Link activeClassName={mobileTypographClasses.active} to="/servicos" className={mobileTypographClasses.root}>
              <ListItemText disableTypography primary={'Serviços'} style={{color: 'white'}} />
            </Link>
          </ListItem>
          <Divider classes={{root: classes.divider}} />
          <ListItem button className={clsx(classes.nested, classes.mobileSubListItem)} key={'Energy'} onClick={handleDrawerClose}>
            <Link activeClassName={mobileTypographClasses.active} to="/servicos" className={mobileTypographClasses.subItem}>
              <ListItemText disableTypography primary={'Energy Service'} style={{color: 'white'}} />
            </Link>
          </ListItem>
          <Divider classes={{root: classes.divider}} />
          <ListItem button className={clsx(classes.nested, classes.mobileSubListItem)} key={'Wind'} onClick={handleDrawerClose}>
            <Link activeClassName={mobileTypographClasses.active} to="/servicos/wind" className={mobileTypographClasses.subItem}>
              <ListItemText disableTypography primary={'Energy Wind Service'} style={{color: 'white'}} />
            </Link>
          </ListItem>
          <Divider classes={{root: classes.divider}} />
          <ListItem button className={classes.mobileListItem} key={'Contato'} onClick={handleDrawerClose}>
            <Link activeClassName={mobileTypographClasses.active} to="/contato" className={mobileTypographClasses.root}>
              <ListItemText disableTypography primary={'Contato'} style={{color: 'white'}} />
            </Link>
          </ListItem>
        </List>
        <Grid container direction="row" justify="center" alignItems="center" item style={{marginTop: '150px'}}> 
          <Logo onClick={() => history.push("/")} src={WegWhiteSvg} />
        </Grid>
      </Drawer>
    </div>
  );
}
