import React from "react";

import { Switch, Redirect } from "react-router-dom";

import Route from "./Route";
import Home from "~/containers/Home";
import Contact from "~/containers/Contact";
import About from "~/containers/About";
import Services from "~/containers/Services";
import ScrollToTop from "~/components/ScrollToTop";

export default function Routes() {
  return (
    <ScrollToTop>
      <Switch>
        <Route path="/contato" exact name="Contato" component={Contact} />
        <Route path="/servicos" name="Serviços" component={Services} />
        <Route path="/servicos/wind" name="Serviços" component={Services} />
        <Route path="/institucional" exact name="Institucional" component={About} />
        <Route path="/" name="Home" exact component={Home} />
        <Route component={<>Deu ruim</>} name="404" path="*">
          <Redirect to="/" />
        </Route>
      </Switch>
    </ScrollToTop>
  );
}
