import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const DefaultButton = withStyles({
  root: {
    backgroundColor: '#2eb673',
    paddingLeft: '30px',
    paddingRight: '30px',
    height: '64px',
    minWidth: '164px',
    color: '#ffffff',
    fontSize: '16px',
    fontWeight: 'normal',
    lineHeight: 1.38,
    textTransform: 'none',
    borderRadius: '0',
    '&span': {
      display: 'inline-block',
      transition: '0.6s ease-in',
    },
    '&:hover': {
      backgroundColor: '#49d38f',
    },
    '&:hover span span': {
      transform: 'translateX(50%)'
    },
  },
  disabled: {
    // color: '#ea9c16 !important'
  }
})(Button);

export default function CustomButton(props) {
  let Button = DefaultButton;

  return <Button disableRipple {...props}>
    {props.children}
  </Button>;
}
