import React from "react";
import PropTypes from "prop-types";
import { Route } from "react-router-dom";

import DefaultLayout from "../components/layout";

// import { store } from "~/store";

export default function RouteWrapper({
  component: Component,
  name,
  ...rest
}) {
  // const { signed } = store.getState().auth;

  return (
    <Route
      {...rest}
      render={(props) => (
        <DefaultLayout title={name}> 
          <Component {...props} />
        </DefaultLayout>
      )}
    />
  );
}

RouteWrapper.propTypes = {
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func])
    .isRequired,
};