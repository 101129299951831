import { takeLatest, call, put, all } from "redux-saga/effects";

import api from "~/shared/api";
import { setHighlights} from '~/store/modules/highlights/actions';

export function* fetchHighlights() {
  try {
    let url = `/highlights/all.json`;

    const { data } = yield call(api.get, url);

    yield put(setHighlights(data.highlights));

  } catch (err) {
  }
}

export default all([
  takeLatest("@highlights/fetchHighlights", fetchHighlights),
]);
