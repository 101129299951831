import React from 'react';

import styled from 'styled-components';

import { makeStyles, withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { Hidden } from '@material-ui/core';

import Icon from '~/components/Icon';
import { Title, Title2 } from '~/components/Contact/elements';

 const useStyles = makeStyles((theme) => ({
  icon: {
    fontSize: '17px'
  }
}));

const SubTitle = withStyles((theme) => ({
  root: {
    fontSize: '20px',
    fontWeight: 'normal',
    lineHeight: 1.2,
    fontStretch: 'normal',
    fontStyle: 'normal',
    letterSpacing: 'normal',
    color: '#262626',
  },
}))(Typography);

const SubTitle2 = withStyles((theme) => ({
  root: {
    fontSize: '14px',
    fontWeight: 'normal',
    lineHeight: 1.21,
    fontStretch: 'normal',
    fontStyle: 'normal',
    letterSpacing: 'normal',
    color: '#525252',
  },
}))(Typography);

const IconBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background-color: #283790;
  // color: #ffffff;
`;

function Contact (props) {
  const classes = useStyles();

  return (
    <Grid item xs={12} md={4}>
      <Grid container>
        <Hidden smDown>
          <Grid item xs={12}> 
            <Title>Entre em</Title>
            <Title2 style={{marginBottom: '32px'}}>contato</Title2>
          </Grid>
        </Hidden> 

        <Grid container> 
          <Grid item xs={2}>
            <IconBox>
              <Icon className={classes.icon} type="contact_phone" />
            </IconBox> 
          </Grid>
          <Grid item> 
            <Grid item xs={12}>
              <SubTitle>+55 81 97344-0110</SubTitle> 
            </Grid>
            <Grid item xs={12}> 
              <SubTitle2>Converse com a gente!</SubTitle2> 
            </Grid>
          </Grid>
        </Grid>

        <Grid container style={{marginTop: '40px'}}> 
          <Grid item xs={2}>
            <IconBox>
              <Icon className={classes.icon} type="contact_hour" />
            </IconBox> 
          </Grid>
          <Grid item> 
            <Grid item xs={12}>
              <SubTitle>Horários</SubTitle> 
            </Grid>
            <Grid item xs={12}> 
              <SubTitle2>Seg - Sex: 8h - 18h</SubTitle2> 
            </Grid>
          </Grid>
        </Grid>

        <Grid container style={{marginTop: '40px', marginBottom: '40px'}}> 
          <Grid item xs={2}>
            <IconBox>
              <Icon className={classes.icon} type="contact_location" />
            </IconBox> 
          </Grid>
          <Grid item> 
            <Grid item xs={12}>
              <SubTitle>Localização</SubTitle> 
            </Grid>
            <Grid item xs={12}> 
              <SubTitle2>BR101</SubTitle2> 
            </Grid>
          </Grid>
        </Grid>

      </Grid>
    </Grid>
  );
}

export default React.memo(Contact);