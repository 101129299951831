export function fetchHighlights() {
  return {
    type: '@highlights/fetchHighlights',
  };
}

export function setHighlights(items) {
  return {
    type: '@highlights/setHighlights',
    payload: {items}
  };
}