import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

export const Title = withStyles((theme) => ({
  root: {
    fontSize: '20px',
    fontWeight: 'normal',
    lineHeight: 1.2,
    fontStretch: 'normal',
    fontStyle: 'normal',
    letterSpacing: 'normal',
    color: '#707070',
    // marginTop: '53px'
  },
}))(Typography);

export const Title2 = withStyles((theme) => ({
  root: {
    fontSize: '40px',
    fontWeight: 'bold',
    lineHeight: 1.18,
    fontStretch: 'normal',
    fontStyle: 'normal',
    letterSpacing: 'normal',
    color: '#262626',
    // marginBottom: '59px'
  },
}))(Typography);