import React  from 'react';

import styled from 'styled-components';

import { makeStyles, withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { Box, Hidden } from '@material-ui/core';

import Timeline from '~/assets/img/timeline_desktop.svg';
import MobileTimeline from '~/assets/img/timeline_mobile.svg';
import BannerImage from '~/assets/img/about_banner.png';
import AboutImage from '~/assets/img/about.jpg';

import Container from '~/components/Container';
import Help from '~/components/Help';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: '#ffffff',
  },
  li: {
    marginTop: '8px'
  }
}));

const Title = withStyles((theme) => ({
  root: {
    marginTop: '80px',
    fontSize: '56px',
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    letterSpacing: 'normal',
    color: '#202020',
  },
}))(Typography);

const Title2 = withStyles((theme) => ({
  root: {
    fontSize: '32px',
    fontWeight: 'bold',
    lineHeight: 1.25,
    fontStretch: 'normal',
    fontStyle: 'normal',
    letterSpacing: 'normal',
    color: '#525252',
  },
}))(Typography);

const Text = withStyles((theme) => ({
  root: {
    fontSize: '22px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.82,
    letterSpacing: 'normal',
    color: '#707070',
  },
}))(Typography);

const Text2 = withStyles((theme) => ({
  root: {
    marginTop: '8px',
    fontSize: '18px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.44,
    letterSpacing: 'normal',
    color: '#525252',
  },
}))(Typography);

const Banner = styled.div`
  width: 100%;
  height: 400px;  
  display: flex;
  img {
    width: 100%;
    object-fit: cover;
  }
`;

const Image = styled.div`
  width: 100%;
  height: 384px;  
  display: flex;
  img {
    width: 100%;
    object-fit: cover;
  }
`;

const Image3 = styled.div`
  margin-top: 64px;
  width: 100%;
  display: flex;
  img {
    width: 100%;
    object-fit: cover;
  }
`;

const Ul = styled.ul`
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #525252;
  li::before {
    content: "• ";
  }
`;

function About () {
  const classes = useStyles();

  return <div className={classes.root}>
    <Grid container>
      <Banner>
        <img src={BannerImage} alt="banner" />
      </Banner>
    </Grid>
    <Container>
      <Title>A Energy Service</Title>
      <Text style={{marginTop: '40px'}}>Somos uma empresa especializada na manutenção e reparo de motores elétricos, geradores e transformadores. Dispomos de tecnologia de ponta, uma infraestrutura moderna e um quadro de funcionários altamente qualificado para atender a demanda dos nossos clientes.</Text>
      <Text style={{marginTop: '30px'}}>Nossa larga experiência em  recuperação de máquinas elétricas é resultado da longa história da nossa equipe atuando no setor. Nossos membros passaram por grandes empresas especializadas nesse mercado, como Koblitz, Alstom, Cegelec, Efacec e Weg.</Text> 
      <Text style={{marginTop: '30px', marginBottom: '72px'}}>Unindo nossa expertise, estrutura física e motivação, estamos aptos a realizar análises precisas e executar um trabalho dentro dos melhores padrões do mercado. Com isso, buscamos entregar um serviço de alta eficiência e qualidade para satisfazer ao máximo nossos clientes.</Text>
      <Grid container style={{marginBottom: '72px'}}>
        <Grid item xs={12}>
          <Box mr={{md: '15px'}}>
            <Image>
              <img src={AboutImage} alt="about image" />
            </Image>
          </Box>
        </Grid>
      </Grid>
      <Grid container justify="space-between">
        <Grid item xs={12} md={4}>
          <Box mb={{xs: '24px', md: 0}}>
            <Title2>Missão</Title2>
            <Text2>Realizar manutenção em equipamentos elétricos, aplicando tecnologia moderna, garantindo um serviço de excelência e a satisfação das partes interessadas no negócio</Text2>
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box mb={{xs: '24px', md: 0}}>
            <Title2>Visão</Title2>
            <Text2>Ser reconhecida nacionalmente pela eficiência e qualidade em serviços de manutenção e reparo de grandes máquinas elétricas.</Text2>
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box>
            <Title2>Valores</Title2>
            <Ul>
              <li className={classes.li}>Ética e transparência</li>
              <li>Disposição para mudanças e inovação</li>
              <li>Foco em pessoas e resultados</li>
              <li>Respeito aos nossos colaboradores, clientes, fornecedores e parceiros</li>
            </Ul>
          </Box>
        </Grid>
      </Grid>
     
      <Title>História</Title>
      <Text style={{marginTop: '40px'}}>A longa história da Energy Service inicia seu curso na década de 80, quando membros da equipe atuavam na Koblitz no setor de recuperação de máquinas elétricas. No início dos anos 90, a Koblitz passou para o controle da Alstom/Gec Alstom e em seguida, para Cegelec. Esta última, deixa o mercado em 2004, vendendo seu maquinário e estrutura física para os atuais proprietários da empresa, nascendo assim a Energy Service.</Text>
      <Text style={{marginTop: '30px'}}>Com uma grande paixão por máquinas elétricas e um vasto conhecimento adquirido ao longo dos anos, a empresa começa a atuar fortemente neste mercado, até que em 2007 se torna sócia da Efacec Portugal, maior fabricante de transformadores do seu país.</Text>
      <Text style={{marginTop: '30px'}}>Em 2015, a Efacec Energy Service é comprada pela Weg e passa a ser chama de Transformadores Nordeste Ltda, que segue com às atividades de recuperação de máquinas elétricas até 2017, quando então, decide concentrar o seu escopo de serviços apenas na recuperação e fabricação de transformadores. Desta forma, a Energy Service ressurge, ainda no mesmo ano, dando segmento às atividades de manutenção de motores e geradores e serviço de campo.</Text>
      <Text style={{marginTop: '30px'}}>No ano de 2020, além da empresa retomar às atividades de reparação e rebobinamento de transformadores de potência, é criada a Energy Wind Service, a qual busca atender as demandas do mercado de energia renovável, focando em serviços de manutenção de geradores para turbinas eólicas.</Text>
     
      <Image3 style={{marginBottom: '120px'}}>
        <Hidden only="xs">
          <img src={Timeline} alt="timeline" />
        </Hidden>
        <Hidden smUp>
          <img src={MobileTimeline} alt="timeline" />
        </Hidden>
      </Image3>
    </Container>
    <Help>Converse com a gente.</Help>
  </div>
}

export default About;