import React from "react";

import clsx from "clsx";

import { makeStyles, withStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';

import styled from "styled-components";
import { Field } from "formik";
import { DatePicker } from 'formik-material-ui-pickers';

import Icon from '~/components/Icon';

const Component = styled(Field)``;

const useStyles = makeStyles((theme) => ({
  root: {
    color: 'black !important',
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  underline: {
    color: '#f8f7ee',
    transform: 'scaleX(1)',
    '&:after': {
      borderBottom: '2px solid #d9d499',
    },
    '&:before': {
      borderBottom: '2px solid #d9d499',
    },
    '&:hover:not(.Mui-disabled):before': {
      borderBottom: '2px solid #d9d499',
    },
  },
}));

const Label = withStyles({
  root: {
    color: 'black',
    fontSize: '14px'
  },
})(InputLabel);

const Text = props => {
  const classes = useStyles();
  const attr = {...props};

  let label = null;
  if (attr.label) {
      label = <Label htmlFor={props.id}>{attr.label}</Label>;
      attr.label = false;
  }

  const inputStyle = { color: 'black !important' };
  // const inputStyle = {};
  const InputProps = {
    className: clsx(classes.root, classes.underline),
    endAdornment: (<InputAdornment position="end">
      <Icon style={{marginBottom: '16px'}} type="scheduling" viewBox="0 0 24 26" />
    </InputAdornment>)
  }

  return <React.Fragment>
    {label}
    <Component 
      {...attr} 
      component={DatePicker} 
      InputProps={InputProps} 
      inputProps={{style: inputStyle}}
      className={clsx({ ...attr.className })} 
      invalidDateMessage="Data em formato inválido" 
      invalidLabel="Data em formato inválido" 
      cancelLabel="Cancelar" 
      disableToolbar 
      disablePast 
      autoOk 
    >
      {attr.children}
    </Component>
  </React.Fragment>;
} 

export default Text;
