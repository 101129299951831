import produce from 'immer';

const INITIAL_STATE = {
  items: []
};

export default function reducer(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case '@highlights/setHighlights': {
        draft.items = action.payload.items;
        break;
      }
      default:
    }
  });
}
